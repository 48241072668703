<template>
  <div @click.stop="">
    <div :class="{'nav-fixed':fixed}" :style="style">
      <div class="kuang">
        <div class="left" @click="fanHui" v-if="showReturn">
          <i class="left-icon iconfont  icon-arrow-left-bold"></i>
        </div>
        <div class="right">
          <van-form @submit="onSubmit">
            <van-field
                v-model="keyword"
                :placeholder="placeholder"
            />
            <van-button native-type="submit" type="primary" size="small" class="sou-suo">
              搜索
            </van-button>
          </van-form>
        </div>
      </div>
      <van-popup v-model:show="showSelect" round position="bottom">
        <van-picker
            :columns="fenLei"
            @confirm="onConfirm"
            @cancel="onCancel"
            @change="onChange"
        />
      </van-popup>
    </div>
    <div class="zhan-wei" v-if="fixed"></div>
  </div>
</template>

<script>
//<header-selete placeholder="需求名称搜索" @submit="resetList" ref="headerSelete" />
//this.where.keyword = this.$refs.headerSelete.getKeyword()
import divFixed from "@/views/open/divFixed.vue";

export default {
  name: 'headerSelete',
  components: {divFixed},
  mixins: [],
  emits: ['submit'],
  props: {
    style:Object,
    to:Object,
    showReturn:{
      type:Boolean,
      default() {
        return true;
      }
    },
    fixed: Boolean, //true, //浮动
    placeholder: {
      type: String,
      default() {
        return "请输入关键词"
      }
    },

    id: String
  },
  data() {
    return {
      showSelect: false,
      activeId: '',
      activeIndex: '',
      keyword: '',
      type: '',
    }
  },
  mounted() {
    if (typeof this.$route.query.keyword !== 'undefined') {
      this.keyword = this.$route.query.keyword
    }
  },
  methods: {
    fanHui(){
      if(_.isPlainObject(this.to)){
        this.$router.push(this.to)
        return
      }
      this.$router.back(-1)

    },
    onSubmit() {
      console.log('ti jiao')
      this.$emit('submit', this.keyword)
    },
    onConfirm(val) {
      this.type = val
      this.showSelect = false
    },
    onCancel() {
      this.showSelect = false
    },

    getKeyword() {
      return this.keyword
    }
  },
}

</script>

<style scoped lang="less">
@import "../../assets/style";

.kuang {
  display: flex;
  padding: 6px 5px 6px;
  background-color: @bai-se;

  .left {
    position: relative;
    width: 30px;
    text-align: center;
    color: #4A4096;
    font-weight: 700;
    padding-top: 6px;
    line-height: 30px;

    .left-icon {
      margin-top: 2px;
      //font-size: @fan-hui-icon;
    }
  }

  .right {
    flex: 1;
    padding: 0 5px;
    position: relative;

    ::v-deep .van-field {
      padding-top: 8px;
      padding-bottom: 5px;
      background-color: @hui-se;
      border-radius: @radius;
    }

    .sou-suo {
      position: absolute;
      top: 4px;
      right: 8px;
      border-radius: @radius;
      padding: 0 10px;
      font-size: 16px;
      height: 30px;
    }

    .select-type {
      position: absolute;
      left: 17px;
      top: 11px;
      z-index: 10;
      display: block;
      width: 70px;
      font-size: 14px;

      .select-type-icon {
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
}

.nav-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: @bai-se;
  z-index: 5;
}
.zhan-wei{
  height: 50px;
}

.left-icon {
  font-size: 18px;
}
</style>
