<template>
  <div>
    <div>
      <div
          class="biao-qian"
          :class="`size-${size}`"
          v-for="(vo,index) in getList.splice(0,maxLength)"
      >
        {{ vo }}
      </div>
      <div
          class="biao-qian"
          :class="`size-${size}`"
          v-if="showgenDuo"
      >
        更多>>
      </div>
    </div>
    <div style="clear: both"></div>
  </div>
</template>

<script>
import XEUtils from "xe-utils";
//标签
export default {
  name: 'biaoQian',
  mixins: [],
  props: {
    size: String,
    list: Array,
    maxLength: {
      type: [Number, String],
      default() {
        return 100
      }
    }
  },
  mounted() {
  },
  methods: {},
  watch: {},
  computed: {
    showgenDuo() {
      if (XEUtils.isUndefined(this.maxLength)) {
        return false
      }

      return this.list.length > this.maxLength

    },
    getList() {
      if (XEUtils.isArray(this.list)) {
        return this.list
      }
      return []
    }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.biao-qian {
  padding: 0 5px;
  border: 1px solid #d2cfe5;
  color: @blue;
  background-color: #EDECF5;
  border-radius: 4px;
  font-size: 13px;
  float: left;
  margin: 2px 4px 5px;
}

.size-lg {
  font-size: 14px;
  padding: 2px 9px;
}
</style>
