<template>
  <header-selete @submit="resetList" ref="headerSelete" fixed placeholder="案例名称搜索"/>
  <xia-la-load ref="xiaLa" @load="getLoad">
    <div class="case" v-for="vo in dataList" :key="vo.id">
      <div class="case-image">
        <van-image
            class="zhu-tu"
            height="103"
            width="166"
            :src="vo.image"
        />
      </div>
      <div class="case-info">
        <div class="case-name">
          {{ vo.title }}
        </div>
        <div class="case-type">
          <biao-qian :list="[vo.type_name]"/>
        </div>
        <div class="case-status hui-se">
          发布时间：{{ vo.create_time }}
        </div>
        <div class="case-edit hui-se ">
          <div class="case-create-time van-ellipsis">
            状态：<a href="javascript:">{{ vo.state }}</a>
          </div>
          <div class="case-btn">
            <a href="javascript:" @click="del(vo.id)">删除</a>
            <router-link :to="{name:'UserCaseEdit',query:{id:vo.id}}">编辑</router-link>
          </div>
        </div>
      </div>
    </div>
  </xia-la-load>
  <footer-btn text="添加案例" url-name="UserCaseEdit"/>
</template>

<script>
import HeaderSelete from "../open/headerSelete";
import XiaLaLoad from "../../components/list/XiaLaLoad";
import http from "../../api/http";
import FooterBtn from "../../components/btn/FooterBtn";
import BiaoQian from "../open/biaoQian";
import moment from "moment";
import {Dialog} from 'vant'
import XEUtils from "xe-utils";

export default {
  name: 'CaseList',
  components: {BiaoQian, FooterBtn, XiaLaLoad, HeaderSelete},
  mixins: [],
  data() {
    return {
      where: {},
      dataList: []
    }
  },
  mounted() {
  },
  methods: {
    resetList() {
      this.$refs.xiaLa.resetData()
    },
    getLoad(page) {
      this.where.page = page
      this.where.keyword = this.$refs.headerSelete.getKeyword()
      http.get('/gzhphp/user_case/getList', {params: this.where}).then(da => {
        this.$refs.xiaLa.setLoadSuccess2(da).then(({page, item}) => {
          item = item.map(n => {
            if (n.image.length) {
              n.image = n.image[0].url
            }
            n.create_time = moment(n.create_time * 1000).format('YYYY-MM-DD')
            return n
          })
          //修改数据
          if (page === 1) this.dataList = item
          else this.dataList = [...this.dataList, ...item]
        })
      })
    },
    del(id) {
      Dialog.confirm({
        title: '提示',
        message: '确定要删除'
      }).then(da => {
        http.post('/gzhphp/user_case/del', {id: id}).then(da => {
          if (da.code === 1) {
            let aaa = XEUtils.remove(this.dataList, n => n.id === id)
            console.log(aaa)
          }
        })
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.case {
  display: flex;
  background-color: @white;
  box-shadow: @yin-ying;
  padding: 10px;
  margin: 10px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;

  .case-image {
    width: 166px;
    //padding-right: 10px;
    .zhu-tu {
      border-radius: 5px;
      overflow: hidden;
    }
  }

  .case-info {
    flex: 1;
    padding-left: 10px;

    .case-name {
      font-size: 16px;
      font-weight: bold;
    }

    .case-edit {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;

      a {
        margin-left: 5px;
      }
    }

    .case-name, .case-type, .case-status {
      margin-bottom: 3px;
    }

    .hui-se {
      color: @gray-6;
    }

  }

}

</style>
