<template>
  <div class="btn-div max-width" v-if="!is_fixed">
    <div class="btn-content" @click="dian_ji">
      <van-button name="chat-o" type="primary" block>
        <i class="iconfont iconxuanfutianjia"></i>
        {{text}}
      </van-button>
    </div>
  </div>
  <div class="fo-dong-btn max-width van-button--primary" v-else @click="dian_ji">
    <i class="iconfont iconxuanfutianjia"></i>
  </div>
</template>

<script>
  import {Options, Vue} from 'vue-class-component';
  //底部按钮
  export default {
    name: 'FooterBtn',
    props: {
      //是否显示圆角button
      is_fixed: {
        type: Boolean,
        default() {
          return false
        }
      },
      text: {
        type: String,
        default() {
          return '新增'
        }
      },
      urlName: {
        type: String,
        default() {
          return ''
        }
      }

    },
    methods: {
      dian_ji() {
        if (this.urlName === '') {
          this.$emit('click')
        } else {
          this.$router.push({name: this.urlName})
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .btn-content {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  .btn-div, .btn-content {
    height: 44px;
  }

  .fo-dong-btn {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 88px;
    right: 15px;
    border-radius: 100%;
    text-align: center;
    line-height: 50px;
    z-index: 10;
  }

  .fo-dong-btn .iconxuanfutianjia {
    font-size: 20px;
  }
</style>
